import { OriginalQuestionType } from '@/types/originalQuestion';
import yup from '@/yup.locale';

export enum ReviewType {
  BAD,
  GOOD,
}

export enum AssistantToneType {
  NORMAL,
  GAL,
  KANSAI,
  LU,
  UNCLE,
  JAPANESE,
  LADY,
}

export const createRoomSchema = yup.object().shape({
  title: yup.string().required().max(255),
  password: yup.string().optional().max(255),
});

export const updateRoomSchema = yup.object().shape({
  title: yup.string().required().max(255),

  penaltySeconds: yup.number().nullable().max(120),
  questionCooldownSeconds: yup.number().nullable().max(120),
  assistantToneType: yup
    .mixed()
    .oneOf(Object.values(AssistantToneType))
    .nullable()
    .default(AssistantToneType.NORMAL),
  isHintEnabled: yup.boolean().nullable().default(true),
});

export const joinRoomSchema = yup.object().shape({
  roomId: yup.string().required().max(21),
  password: yup.string().optional().max(255),
  userId: yup.string().required().max(21),
});

export const updateUserNameSchema = yup.object().shape({
  name: yup.string().required('validation.user.nameRequired').max(255, 'validation.user.nameMaxLength'),
});

export const startGyakunatorSchema = yup.object().shape({
  questionId: yup.string().required(),
  roomId: yup.string().required(),
  timer: yup
    .number()
    .required()
    .min(2, 'validation.room.timerMin')
    .max(60, 'validation.room.timerMax'),
  question_quantity: yup
    .number()
    .required()
    .min(1, 'validation.room.quantityMin')
    .max(10, 'validation.room.quantityMin'),
  winnersLimit: yup.number().nullable(),
  life: yup.number().nullable().max(10),
});

export const createOriginalQuestionSchema = yup.object().shape({
  title: yup
    .string()
    .required('validation.createOriginalQuestions.titleRequired')
    .max(255, 'validation.createOriginalQuestions.titleMaxLength'),
  type: yup
    .mixed<OriginalQuestionType>()
    .oneOf(
      [OriginalQuestionType.NORMAL, OriginalQuestionType.RANDOM],
      '無効な質問タイプです',
    )
    .nullable('validation.createOriginalQuestions.typeRequired'),
  isPublished: yup.boolean().nullable().default(false),
});

export const createOriginalQuestionItemSchema = yup.object().shape({
  description: yup
    .string()
    .optional()
    .max(500, 'validation.createOriginalQuestionItem.descriptionMaxLength'),
  theme: yup
    .string()
    .required('validation.createOriginalQuestionItem.themeRequired')
    .max(255, 'validation.createOriginalQuestionItem.themeMaxLength'),
  themeDetail: yup
    .string()
    .optional()
    .max(4096, 'validation.createOriginalQuestionItem.themeDetailMaxLength'),
  answer: yup
    .string()
    .required('validation.createOriginalQuestionItem.answerRequired')
    .max(255, 'validation.createOriginalQuestionItem.answerMaxLength'),
});
