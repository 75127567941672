import RootLayout from '@/layouts/root';
import type { AppProps } from 'next/app';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import Head from 'next/head';
import '@/styles/globals.css';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import React from 'react';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { AudioProvider } from '@/context/AudioContext';
import Router, { useRouter } from 'next/router';
import SettingModal from '@/components/_modals/SettingModal';
import { useSessionUserStore } from '@/hooks/stores/useSessionUserStore';
import { AuthProvider } from '@/context/AuthContext';
import { toast } from 'react-toastify';
import useAskCount from '@/hooks/stores/useAskCountStore';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

process.env.GOOGLE_API_KEY = 'AIzaSyCkvJFxpYGnrI4Ku5qlKaV_pc4x9T6Nbbw';

Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

const App = ({ Component, pageProps, router }: AppProps) => {
  // const { isFetching, user, loginError, login, setUser } = useSessionUser();

  const { modalIds } = useSessionUserStore();
  const { i18n, t } = useTranslation();
  const { setAskCount, setPremium } = useAskCount();
  const [displaySettingModal, setDisplaySettingModal] = React.useState(false);
  const idsRef = React.useRef<string[]>([]);
  idsRef.current = modalIds;

  const setWindowMode = () => {
    const mode = localStorage.getItem('windowMode');
    if (mode === 'windowed') {
      window.electronAPI.changeWindowMode('windowed');
    }
  };

  const handleKeydownEsc = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      if (idsRef.current.length > 0) return;

      setDisplaySettingModal(true);
    }
  };

  const handleCloseSettingModal = () => {
    setDisplaySettingModal(false);
  };

  const showFooter = React.useMemo(() => {
    console.log(router.pathname);
    return (
      router.pathname === '/' ||
      router.pathname.startsWith('/original-questions')
    );
  }, [router.pathname]);

  React.useEffect(() => {
    window.flutter_inappwebview?.callHandler('loaded');

    let region = 'jp';
    const setLanguage = async () => {
      const storedRegion = localStorage.getItem('region');
      if (storedRegion) {
        region = storedRegion;
      } else {
        // region = await window.electronAPI.getCountryCode();
      }
      localStorage.setItem('region', region);
      if (i18n && typeof i18n.changeLanguage === 'function') {
        await i18n.changeLanguage(region);
      }
    };

    const initialLogin = async () => {
      await setLanguage();
      // await login(region);
    };

    initialLogin();
    setWindowMode();

    window.onRestoreSuccess = () => {
      setPremium(true);
      toast.success(t('payment.restoreSuccess'));
    };


    window.onRestoreFailure = () => {
      toast.error(t('payment.restoreFailure'));
    };


    window.onAskCountChanged = (askCount: number) => {
      console.log(askCount, 'askCount test');
      setAskCount(askCount);
    };

    document.addEventListener('keydown', handleKeydownEsc);
    document.addEventListener(
      'flutterInAppWebViewPlatformReady',
      function (event) {
        // @ts-ignore
        document.flutter_inappwebview
          .callHandler('handlerFoo')
          // @ts-ignore
          .then(function (result) {
            // print to the console the data coming
            // from the Flutter side.
            console.log(JSON.stringify(result));
            // @ts-ignore

            document.flutter_inappwebview?.callHandler(
              'handlerFooWithArgs',
              1,
              true,
              ['bar', 5],
              { foo: 'baz' },
              result,
            );
          });
      },
    );

    return () => {
      document.removeEventListener('keydown', handleKeydownEsc);
    };
  }, []);

  console.log(showFooter);

  return (
    <RootLayout>
      <Head>
        <title>{t('title')}</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0 user-scalable=no"
        />
      </Head>
      <div className="relative bg-[url('/images/bg_s.png')] h-full bg-cover bg-center bg-no-repeat">
        <AuthProvider>
          <AudioProvider>
            <Component {...pageProps} />
            {displaySettingModal && (
              <SettingModal onClose={handleCloseSettingModal} />
            )}
          </AudioProvider>
        </AuthProvider>
      </div>
    </RootLayout>
  );
};

export default appWithTranslation(App);

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}
