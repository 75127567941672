import React from 'react';
import { useTranslation } from 'next-i18next';
import { Apple } from '@styled-icons/bootstrap/Apple';
import { Steam } from '@styled-icons/bootstrap/Steam';
import { PlayCircle } from '@styled-icons/bootstrap/PlayCircle';

type Provider = 'apple' | 'steam' | 'start';

interface Props {
  provider: Provider;
  className?: string;
  onClick?: () => void;
  text?: {
    ja: string;
    en: string;
  };
}

const PROVIDER_STYLES = {
  apple: {
    bg: 'bg-white hover:bg-gray-50',
    text: 'text-black',
    border: 'border border-gray-200',
    icon: <Apple size={20} />,
    defaultText: {
      ja: 'Appleでサインイン',
      en: 'Sign in with Apple',
    },
  },
  steam: {
    bg: 'bg-[#1b2838] hover:bg-[#2a475e]',
    text: 'text-white',
    border: '',
    icon: <Steam size={20} />,
    defaultText: {
      ja: 'Steamから引き継ぐ',
      en: 'Import from Steam',
    },
  },
  start: {
    bg: 'bg-gray-100 hover:bg-gray-200',
    text: 'text-gray-700',
    border: 'border border-gray-300',
    icon: null,
    defaultText: {
      ja: '引き継がずに始める',
      en: 'Start without importing',
    },
  },
};

export default function SignInButton({
  provider,
  className = '',
  onClick,
  text,
}: Props) {
  const { i18n } = useTranslation();
  const isJapanese = i18n.language === 'jp';
  const styles = PROVIDER_STYLES[provider];

  const buttonText = text ?? styles.defaultText;

  return (
    <button
      onClick={onClick}
      className={`inline-flex items-center justify-center px-6 py-3 ${styles.bg} ${styles.text} ${styles.border} rounded-lg transition-colors ${className}`}
      style={{
        minWidth: '200px',
        minHeight: '44px',
        fontFamily:
          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif',
        fontSize: '17px',
        fontWeight: 400,
      }}
    >
      {styles.icon && <div className="w-5 h-5 mr-3 flex">{styles.icon}</div>}
      <span>{isJapanese ? buttonText.ja : buttonText.en}</span>
    </button>
  );
}
