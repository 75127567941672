import * as React from 'react';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const Radio = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    if (props.checked || !props.onClick) return;
    props.onClick(e);
  };

  return (
    <label className="flex items-center gap-2 cursor-pointer">
      <input
        type="radio"
        name="custom-radio"
        className="hidden"
        value={props.value}
        defaultChecked={props.checked}
        onClick={handleClick}
      />
      <div
        className={`w-10 h-10 ${
          props.className
        } bg-center bg-cover rounded-full ${
          props.checked
            ? 'bg-[url("/images/radio_on.png")]'
            : 'bg-[url("/images/radio_off.png")]'
        }`}
        {...props}
      />
      <span>{props.label}</span>
    </label>
  );
});

Radio.displayName = 'Radio';

export default Radio;
