import {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import { signInAnonymously } from 'firebase/auth';
import { auth } from '../firebase';
import { useSessionUser } from '@/hooks/useSessionUser';
import { SteamUser } from '@/types/user';
import EdgeButton from '@/components/_buttons/EdgeButton';
import { AudioProvider } from './AudioContext';
import { useTranslation } from 'next-i18next';
import { BASEURL, SupportedLanguage } from '@/settings/endpoint';
import { axios } from '@/libs/api';

interface AuthContextProps {
  user: SteamUser | null;
  token: string | null;
  loading: boolean;
  noUserName: boolean;
  handleSetUser: () => void;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { i18n } = useTranslation();
  const { user, login } = useSessionUser();
  const [noUserName, setNoUserName] = useState(false);
  const [idToken, setIdToken] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  const handleSetUser = () => {
    setNoUserName(false);
  };

  const handleReload = () => {
    window.location.reload();
  };

  const _login = async (language: string) => {
    if (!i18n) return;
    try {
      const user = await signInAnonymously(auth);
      const token = await user.user?.getIdToken();
      setIdToken(token);
      const storedUserName = localStorage.getItem('userName');
      if (!storedUserName) {
        setNoUserName(true);
        return;
      }

      const isJapanese = language === 'ja';
      const baseUrl = isJapanese ? BASEURL.jp.appUrl : BASEURL.us.appUrl;
      const i18nLanguage = isJapanese ? 'jp' : 'us';

      axios.defaults.baseURL = baseUrl;
      if (i18n && typeof i18n.changeLanguage === 'function') {
        await i18n.changeLanguage(i18nLanguage);
      }
      await login(storedUserName, token, i18nLanguage as SupportedLanguage);
    } catch (error) {
      console.error(error);
      setErrorMessage('ログインに失敗しました。');
    }
    setLoading(false);
  };

  useEffect(() => {
    window.onLanguageChanged = (language: string) => {
      _login(language);
    };
  }, [i18n]);

  return (
    <AuthContext.Provider
      value={{ user, token: idToken, loading, noUserName, handleSetUser }}
    >
      {errorMessage ? (
        <AudioProvider>
          <div
            className="fixed left-[50%] flex flex-col text-white items-center gap-4 justify-center"
            style={{ top: '50%', transform: 'translate(-50%, -50%' }}
          >
            <div className="text-2xl">{errorMessage}</div>
            <EdgeButton onClick={handleReload}>ページを更新</EdgeButton>
          </div>
        </AudioProvider>
      ) : (
        <> {children}</>
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
