import * as React from 'react';
import { useAudio } from '@/context/AudioContext';
import Radio from '../_inputs/Radio';
import { useTranslation } from 'next-i18next';

const bgmList = [
  { title: 'BGM1', bgm: '/audios/bgm_1.mp3' },
  { title: 'BGM2', bgm: '/audios/bgm_2.mp3' },
];

export default function SoundSettings() {
  const {
    bgm,
    bgmVolume,
    soundEffectVolume,
    isMutedBGM,
    changeBgm,
    changeBGMVolume,
    changeSoundEffectVolume,
    toggleMute,
  } = useAudio();

  const { t } = useTranslation();

  const handleChangeBGMVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVolume = parseFloat(e.target.value);
    changeBGMVolume(newVolume);
  };

  const handleChangeSoundEffectVolume = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newVolume = parseFloat(e.target.value);
    changeSoundEffectVolume(newVolume);
  };

  const handleChangeBGM = (bgmPath: string) => {
    changeBgm(bgmPath);
  };

  return (
    <div className="flex justify-center gap-4">
      {bgmList.map((item) => (
        <div
          key={item.bgm}
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => handleChangeBGM(item.bgm)}
        >
          <Radio checked={bgm === item.bgm} label={item.title} />
        </div>
      ))}
    </div>
  );
}
