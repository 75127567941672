// store.ts
import create from 'zustand';

interface AppState {
  askCount: number;
  loginDate: string;
  steamUser: boolean;
  isPremium: boolean;
  // アクション
  setAskCount: (count: number) => void;
  resetAskCount: () => void;
  setLoginDate: (date: string) => void;
  setPremium: (isPremium: boolean) => void;
  setSteamUser: (isSteamUser: boolean) => void;
}

const useAskCount = create<AppState>((set) => ({
  // 状態の初期値
  askCount: 20,
  loginDate: new Date().toISOString(),
  steamUser: false,
  isPremium: false,
  // アクションの定義
  setAskCount: (count: number) => set({ askCount: count }),
  resetAskCount: () => set({ askCount: 20 }),
  setLoginDate: (date: string) => set({ loginDate: date }),
  setPremium: (isPremium: boolean) => set({ isPremium }),
  setSteamUser: (isSteamUser: boolean) => set({ steamUser: isSteamUser }),
}));

export default useAskCount;
