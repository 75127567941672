'use client';

import * as React from 'react';
import { useTranslation } from 'next-i18next';

import { ForwardRefHandlers, ModalLayout } from '../ModalLayout';
import { Button } from '@/components/_catalyst/button';
import { useSessionUser } from '@/hooks/useSessionUser';
import api from '@/libs/api';
import { Avatar as AvatarType } from '@/types/avatar';
import { Avatar } from '@/components/_catalyst/avatar';
import EdgeButton from '@/components/_buttons/EdgeButton';
import { SteamUser } from '@/types/user';
import Image from 'next/image';

interface Props {
  userAvatarId: string | null;
  onClose?: () => void;
  onSubmit?: (user: SteamUser) => void;
}

export default function UserAvatarUpdateModal({
  userAvatarId,
  onClose,
  onSubmit,
}: Props) {
  const { t } = useTranslation();

  const ref = React.useRef<ForwardRefHandlers>(null);
  const [avatars, setAvatars] = React.useState<AvatarType[]>([]);
  const [loadedAvatars, setLoadedAvatars] = React.useState<string[]>([]);
  const [selectedAvatarId, setSelectedAvatarId] = React.useState<string | null>(
    userAvatarId,
  );

  const { user, fetchAvatars: getAvatars, updateAvatar } = useSessionUser();

  const fetchAvatars = React.useCallback(async () => {
    const res = await getAvatars();
    if (!res) return;
    setAvatars(res);
  }, []);

  const handleClose = React.useCallback(() => {
    ref.current?.close();
  }, []);

  const handleImageLoad = (avatarId: string) => {
    setLoadedAvatars((prevLoadedAvatars) => [...prevLoadedAvatars, avatarId]);
  };

  const handleChangeAvatar = (avatarId: string) => {
    if (avatarId === selectedAvatarId) {
      setSelectedAvatarId(null);
      return;
    }
    setSelectedAvatarId(avatarId);
  };

  const allAvatarsLoaded =
    avatars.length > 0 &&
    avatars
      .filter((v) => v.isOwned)
      .every((avatar) => loadedAvatars.includes(avatar.id));

  const handleUpdateAvatar = React.useCallback(async () => {
    if (!user || !selectedAvatarId) return;
    if (user.avatar && user.avatar.id === selectedAvatarId) {
      handleClose();
      return;
    }
    const res = await updateAvatar(selectedAvatarId);
    if (!!res && typeof onSubmit === 'function') {
      onSubmit(res);
    }
    handleClose();
  }, [user, selectedAvatarId, onSubmit]);

  const hasAvatar = React.useMemo(() => {
    return avatars.some((avatar) => avatar.isOwned);
  }, [avatars]);

  React.useEffect(() => {
    fetchAvatars();
  }, []);

  return (
    <ModalLayout
      className="!h-auto"
      width={520}
      styles={{ backgroundSize: '100% 100%' }}
      ref={ref}
      onClose={onClose}
    >
      <div className="p-5 h-full">
        {!!avatars.length ? (
          <>
            <div className="text-brown-primary text-2xl font-bold mb-4">
              {t('options.game.avatarModalTitle')}
            </div>
            <div
              className={`p-5 pt-0 grid grid-cols-6 xs:grid-cols-4 s:grid-cols-4 justify-items-center gap-4 ${
                allAvatarsLoaded ? 'visible' : 'invisible'
              }`}
            >
              {avatars.map((avatar) => (
                <React.Fragment key={avatar.id}>
                  {avatar.isOwned ? (
                    <Image
                      className={`size-16 xs:size-12 s:size-12 cursor-pointer rounded-full ${
                        selectedAvatarId === avatar.id
                          ? 'border-2 border-green-500'
                          : 'border-2 border-transparent'
                      }`}
                      alt="avatar"
                      width={32}
                      height={32}
                      src={`/avatars/${avatar.id}.jpg`}
                      onLoad={() => handleImageLoad(avatar.id)}
                      onClick={() => handleChangeAvatar(avatar.id)}
                    />
                  ) : (
                    <Avatar
                      className="size-16 xs:size-12 s:size-12 text-white bg-white dark:bg-white dark:text-black border-2 border-transparent"
                      initials="?"
                      onLoad={() => handleImageLoad(avatar.id)}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
            <div className="flex relative flex-wrap-reverse sm:flex-nowrap gap-4 px-5 justify-center align-center">
              <EdgeButton
                type={'submit'}
                disabled={!hasAvatar}
                className="!w-[120px] !h-[60px] text-lg"
                style={{ backgroundSize: '100% 100%' }}
                onClick={handleUpdateAvatar}
              >
                {t('save')}
              </EdgeButton>
            </div>
          </>
        ) : (
          <div className="h-full text-brown-primary flex items-center justify-center text-xl">
            {t('avatarsNotFound')}
          </div>
        )}
      </div>
    </ModalLayout>
  );
}
