import { useSessionUser } from '@/hooks/useSessionUser';
import { updateUserNameSchema } from '@/schema/validation';
import { yupResolver } from '@hookform/resolvers/yup';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'next-i18next';
import { Apple } from '@styled-icons/bootstrap';

import EdgeButton from '../_buttons/EdgeButton';
import UserAvatarUpdateModal from '../_modals/UserAvatarUpdateModal';
import { SteamUser } from '@/types/user';
import Image from 'next/image';
import Select from '../Select';
import { useRouter } from 'next/router';
import { useRoomStore } from '@/hooks/stores/useRoomStore';
import Input from '../_inputs/Input';
import { useAuth } from '@/context/AuthContext';
import { BASEURL, SupportedLanguage } from '@/settings/endpoint';
import { axios } from '@/libs/api';
import useAskCount from '@/hooks/stores/useAskCountStore';
import SignInButton from '../SignInButton';

const options = [
  {
    label: '日本語(Tokyo)',
    value: 'jp',
  },
  {
    label: 'English(Varginia)',
    value: 'us',
  },
];

interface Props {
  onChangeName?: (userId: string, name: string) => void;
  onChangeAvatar?: (user: SteamUser) => void;
}

type FormData = { name: string };

export default function UserSettings({ onChangeName, onChangeAvatar }: Props) {
  const { user, login, updateUserName } = useSessionUser();
  const { token } = useAuth();
  const router = useRouter();
  const { setRoomId } = useRoomStore();
  const { t, i18n } = useTranslation();
  const [isDisplayAvatarModal, setIsDisplayAvatarModal] = React.useState(false);
  const [isHoverAvatar, setIsHoverAvatar] = React.useState(false);
  const [language, setLanguage] = React.useState(i18n.language ?? 'jp');
  const { steamUser } = useAskCount();

  const handleChangeServer = async (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    i18n.changeLanguage(event.target.value);
    setLanguage(event.target.value);
    const isJapanese = event.target.value === 'jp';
    const baseUrl = isJapanese ? BASEURL.jp.appUrl : BASEURL.us.appUrl;
    axios.defaults.baseURL = baseUrl; // await login(event.target.value);
    localStorage.setItem('region', event.target.value);
    await login(
      user?.name ?? '',
      token ?? '',
      event.target.value as SupportedLanguage,
    );
    setRoomId(undefined);
  };

  const handleOpenAvatarModal = () => {
    setIsDisplayAvatarModal(true);
  };

  const handleCloseAvatarModal = () => {
    setIsDisplayAvatarModal(false);
  };

  const handleHoverAvatar = () => {
    setIsHoverAvatar(true);
  };

  const handleLeaveAvatar = () => {
    setIsHoverAvatar(false);
  };

  React.useEffect(() => {
    // getCountryCode();
  }, []);

  const {
    formState: { errors },
    register,
    trigger,
    handleSubmit,
  } = useForm({
    mode: 'all',
    defaultValues: {
      name: user ? user.name : '',
    },
    values: {
      name: user ? user.name : '',
    },
    resolver: yupResolver(updateUserNameSchema),
  });

  const { onBlur, ...fieldProps } = register('name', {
    required: { value: true, message: t('validation.user.nameRequired') },
  });

  const handleUpdateName = async ({ name }: FormData) => {
    if (!user) return;
    await updateUserName(name);
    if (onChangeName) {
      onChangeName(user.id, name);
    }
  };

  const handleRestorePurchase = () => {
    window.flutter_inappwebview?.callHandler('restorePurchase');
  };

  return (
    <>
      {user && (
        <>
          <form onSubmit={handleSubmit(handleUpdateName)}>
            <div className="p-2 pt-0 mb-2">
              <div className="flex flex-col gap-1">
                <label className="text-left font-bold text-brown-primary block text-lg ">
                  {t('options.game.nickname')}
                </label>
                <div className="flex items-center gap-1">
                  <Input
                    className="text-base h-full max-w-[240px]  2xs:w-full xs:w-full"
                    spellCheck={false}
                    {...register('name')}
                    {...fieldProps}
                  />
                  <EdgeButton
                    type="submit"
                    className="!w-[100px] !h-[50px] s:!w-[60px] s:!h-[40px] xs:!w-[60px] xs:!h-[40px] text-lg xs:text-base flex-shrink-0"
                    style={{ backgroundSize: '100% 100%' }}
                  >
                    {t('save')}
                  </EdgeButton>
                </div>

                {errors.name && (
                  <div className="text-sm text-red-600">
                    {errors.name.message}
                  </div>
                )}
              </div>
            </div>
          </form>
          {router.pathname === '/' && (
            <div className="p-2 pt-0 pb-4 mb-2 max-w-[200px]">
              <label className="text-left font-bold text-brown-primary block text-lg mb-2">
                {t('options.game.language')}
              </label>
              <Select
                className="text-base"
                value={language}
                onChange={handleChangeServer}
              >
                {options.map((option) => (
                  <option
                    key={option.value}
                    className="text-black"
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </Select>
            </div>
          )}

          <div className="p-2 pt-0 mb-2">
            <div className="text-lg font-bold">{t('options.game.avatar')}</div>
            <div className="flex items-center mb-5">
              {user.avatar ? (
                <div className="relative flex items-center gap-8">
                  <Image
                    className={`size-18 rounded-full cursor-pointer z-10 ${
                      isHoverAvatar ? 'opacity-50' : 'opacity-100'
                    }`}
                    width={72}
                    height={72}
                    alt="avatar"
                    src={`/avatars/${user.avatar.id}.jpg`}
                    onClick={handleOpenAvatarModal}
                    onMouseEnter={handleHoverAvatar}
                    onMouseLeave={handleLeaveAvatar}
                  />
                </div>
              ) : (
                <div className="relative flex items-center gap-8">
                  <Image
                    className={`size-18 rounded-full cursor-pointer z-10 ${
                      isHoverAvatar ? 'opacity-80' : 'opacity-100'
                    }`}
                    width={72}
                    height={72}
                    alt="avatar"
                    src={'/images/player_icon_default.png'}
                    onClick={handleOpenAvatarModal}
                    onMouseEnter={handleHoverAvatar}
                    onMouseLeave={handleLeaveAvatar}
                  />
                </div>
              )}
            </div>
            <div className="flex flex-col gap-4">
              <a
                href={`${process.env.NEXT_PUBLIC_APP_HOST}/connect/apple`}
                onClick={() => {
                  localStorage.setItem('launched', 'true');
                }}
              >
                <SignInButton provider="apple" />
              </a>
              {!steamUser && (
                <a href={`${process.env.NEXT_PUBLIC_APP_HOST}/connect/steam`}>
                  <SignInButton provider="steam" />
                </a>
              )}
            </div>
            {isDisplayAvatarModal && (
              <UserAvatarUpdateModal
                userAvatarId={user.avatarId}
                onClose={handleCloseAvatarModal}
                onSubmit={onChangeAvatar}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}
